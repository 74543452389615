/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import { observer } from "mobx-react"
import HeaderPage from "../layout/HeaderPage";
import DrawerMenuPage from "../layout/DrawerMenuPage";
import './UpdatePages.css'
import Spinner from "@components/Spinner";
import AdminStore from "@store/AdminStore";
import HomeStore from "@store/HomeStore";
import { toJS } from "mobx";
import { Helmet } from "react-helmet";
import { CButton, CDropdown, CDropdownItem, CDropdownMenu, CDropdownToggle, CTable, CTableBody, CTableDataCell, CTableHead, CTableHeaderCell, CTableRow } from "@coreui/react-pro";
import { BASE_API_URL_FILE } from "../../../api/config";
import ReactDOM from "react-dom";
import { useNavigate } from "react-router-dom";
import { BottomSheet } from "react-spring-bottom-sheet";
import moment from "moment";
import { showCaregiverProfile, Control } from "../commonFunctions";
import { Images } from "@elements";

const Jobs = observer(() => {
    const tableRef = useRef()
    const tableName = "table1"
    const [paginationLength, setPaginationLength] = useState(25);
    const [jobList, setJobList] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [selectedJob, setSelectedJob] = useState();
    const navigate = useNavigate();
    useEffect(() => {
        AdminStore.getJobs();
    }, []);

    useEffect(() => {
        setJobList(toJS(AdminStore?.data?.jobs))
    }, [AdminStore?.data?.jobs]);

    useEffect(() => {
        $.fn.dataTable.ext.errMode = 'none';
        $($.fn.dataTable.tables(true)).DataTable()
            .columns.adjust();
        const table = $(`#${tableName}`).DataTable(
            {
                data: jobList,
                order: [], // Disable initial sorting
                dom: 'Blfrtip',
                buttons: [
                    'copy', 'csv', 'excel', 'print',
                ],
                pageLength: paginationLength,
                lengthMenu: [25, 50, 100, 500, 1000],
                columns: [
                    {
                        title: "Ref.", data: "id", "render": function (data, type, row, meta) {
                            return data || "-";
                        }
                    },
                    {
                        title: "Location", "render": function (data, type, row, meta) {
                            const truncatedUrl = row?.paycom_url ? row?.paycom_url?.length > 30 ? row?.paycom_url.substring(0, 30) + "..." : row?.paycom_url:'<span class="text-danger">' + "Paycom URL not available!" + '</span>';
                            return row?.city + ", " + row.state + "<br/>" +
                                `<a href="${row?.paycom_url}" target="_blank" class="link-info pointer">` + truncatedUrl + '</a>';
                        }
                    },

                    {
                        title: "Applications", data: "applications_count", "render": function (data, type, row, meta) {
                            return data ? `<b  class="text-info">${data}</b>` : "0"
                        }
                    },
                    {
                        title: "Industry", data: "industry",
                    },
                    {
                        title: "Degree-Specialty", "render": function (data, type, row, meta) {
                            return '<a class="link-info pointer">' + (row?.speciality ? JSON.parse(row?.speciality)?.Name : row?.degree ? JSON.parse(row?.degree)?.Name : "-") + '</a>';
                        }
                    },
                    {
                        title: "Pay Details", "render": function (data, type, row, meta) {
                            return "$" + row?.pay_details_min + " - " + "$" + row?.pay_details_max;
                        }
                    },
                    {
                        title: "Shift Type", data: "shift_type", "render": function (data, type, row, meta) {
                            return data ? JSON?.parse(data)?.map(x => x)?.join(', ') : ""
                        }
                    },
                    {
                        title: "Contract Type", data: "contract_type",
                    },
                    {
                        title: "Gauranteed Hours", data: "gauranteed_hours",
                    },
                    {
                        title: "Weeks", data: "length_weeks",
                    },
                    {
                        title: "State/City Image", data: "image_url", "render": function (data, type, row, meta) {
                            return data ? '<img src="' + BASE_API_URL_FILE + data + '" alt="Job Image" style="max-width: 100px; max-height: 100px;cursor: pointer;" onclick="window.open(\'' + BASE_API_URL_FILE + data + '\', \'_blank\')" />' : "No Image";
                        }
                    },
                    {
                        title: "Exclusive", data: "is_exclusive", "render": function (data, type, row, meta) {
                            return data === 1 ? "Yes" : "No"
                        }
                    },
                    {
                        title: "Status", data: 'status', "render": function (data, type, row, meta) {
                            return data == '1' ? '<span class="text-danger">' + "In-active" + '</span>' : data == '0' ? '<span class="text-success">' + "Active" + '</span>' : 'No Update'
                        }
                    },
                    {
                        title: "Action"
                    },
                ],
                columnDefs: [
                    {
                        targets: 13,
                        responsivePriority: 1,
                        createdCell: (td, cellData, rowData, row, col) =>
                            ReactDOM.render(
                                <div>
                                    <CDropdown variant="btn-group" direction='dropstart' alignment="end">
                                        <CDropdownToggle size="sm" color="transparent" caret={false} href="#" className="p-0"><i className="fa fa-ellipsis-v text-primary"></i></CDropdownToggle>
                                        <CDropdownMenu>
                                            <CDropdownItem className="more-menu">
                                                <CButton onClick={() => {
                                                    onCopy(rowData)
                                                }} size="sm" className="w-100" variant='outline' color="primary" >
                                                    Copy & Add New Job <i className="fas fa-copy"></i>
                                                </CButton>
                                            </CDropdownItem>
                                            <CDropdownItem className="more-menu">
                                                <CButton onClick={() => {
                                                    onEdit(rowData)
                                                }} size="sm" className="w-100" variant='outline' color="primary" >
                                                    Edit <i className="fas fa-edit"></i>
                                                </CButton>
                                            </CDropdownItem>
                                            <CDropdownItem className="more-menu">
                                                <CButton onClick={() => {
                                                    onView(rowData)
                                                }} size="sm" className="w-100" variant='outline' color="primary" >
                                                    View <i className="fas fa-eye"></i>
                                                </CButton>
                                            </CDropdownItem>
                                        </CDropdownMenu>
                                    </CDropdown>
                                </div>
                                , td),
                    },
                    {
                        targets: 12,
                        responsivePriority: 3,
                    },
                    {
                        targets: 2,
                        responsivePriority: 2,
                    },
                ],
                destroy: true,  // I think some clean up is happening here
                searching: true,
                "language": {
                    search: '',
                    searchPlaceholder: "Search",
                    emptyTable: "No Job Found",
                },
                initComplete: function () {
                    this.api()
                        .columns([0, 1, 2, 5, 8, 9, 10])
                        .every(function () {
                            var column = this;
                            var select = $('<br/><input class="form-control " style="width:100%;margin-top:5px" type="text" placeholder="Filter per page" />')
                                .appendTo($(column.header()))

                            $(select).click(function (e) {
                                e.stopPropagation();
                            });
                            $('input', this.header()).on('keyup change clear', function () {
                                if (column.search() !== this.value) {
                                    column.search(this.value).draw();
                                }
                            });
                        });
                    this.api()
                        .columns([3])
                        .every(function () {//industry
                            $(select).click(function (e) {
                                e.stopPropagation();
                            });
                            var column = this;

                            // Create the dropdown with "All" as the default option
                            var select = $('<br/><select class="form-control" placeholder="filter" style="width:100%;margin-top:5px;background-color:white"><option value="" selected>All</option></select>')
                                .appendTo($(column.header()));
                            $(select).click(function (e) {
                                e.stopPropagation();
                            });
                            // Keep track of unique values
                            var uniqueValues = {};

                            // Populate the dropdown with unique values from column 5
                            column.data().each(function (d, j) {
                                // Get the HTML content of the cell
                                var cellText = column.cell(j, 3).node().innerHTML;
                                // Check if the value is unique before adding it to the dropdown
                                if (!uniqueValues[cellText]) {
                                    uniqueValues[cellText] = true;
                                    select.append('<option value="' + cellText + '">' + cellText + '</option>');
                                }
                            });

                            // Add change event listener to the dropdown
                            select.on('change', function () {
                                var val = $.fn.dataTable.util.escapeRegex(
                                    $(this).val()
                                );

                                // Search and filter the table
                                column.search(val ? '^' + val + '$' : '', true, false).draw();
                            });

                        });
                    this.api()
                        .columns([4])
                        .every(function () {//Degree/pprofession
                            $(select).click(function (e) {
                                e.stopPropagation();
                            });
                            var column = this;

                            // Create the dropdown with "All" as the default option
                            var select = $('<br/><select class="form-control" placeholder="filter" style="width:100%;margin-top:5px;background-color:white"><option value="" selected>All</option></select>')
                                .appendTo($(column.header()));
                            $(select).click(function (e) {
                                e.stopPropagation();
                            });
                            // Keep track of unique values
                            var uniqueValues = {};

                            // Populate the dropdown with unique values from column 5
                            column.data().each(function (d, j) {
                                // Get the HTML content of the cell
                                var cellText = column.cell(j, 4).node().innerHTML;
                                // Check if the value is unique before adding it to the dropdown
                                if (!uniqueValues[cellText]) {
                                    uniqueValues[cellText] = true;
                                    select.append('<option value="' + cellText + '">' + cellText + '</option>');
                                }
                            });

                            // Add change event listener to the dropdown
                            select.on('change', function () {
                                var val = $.fn.dataTable.util.escapeRegex(
                                    $(this).val()
                                );

                                // Search and filter the table
                                column.search(val ? '^' + val + '$' : '', true, false).draw();
                            });

                        });
                    this.api()
                        .columns([6])
                        .every(function () {//Shift Type
                            $(select).click(function (e) {
                                e.stopPropagation();
                            });
                            var column = this;

                            // Create the dropdown with "All" as the default option
                            var select = $('<br/><select class="form-control" placeholder="filter" style="width:100%;margin-top:5px;background-color:white"><option value="" selected>All</option></select>')
                                .appendTo($(column.header()));
                            $(select).click(function (e) {
                                e.stopPropagation();
                            });
                            // Keep track of unique values
                            var uniqueValues = {};

                            // Populate the dropdown with unique values from column 5
                            column.data().each(function (d, j) {
                                // Get the HTML content of the cell
                                var cellText = column.cell(j, 6).node().innerHTML;
                                // Check if the value is unique before adding it to the dropdown
                                if (!uniqueValues[cellText]) {
                                    uniqueValues[cellText] = true;
                                    select.append('<option value="' + cellText + '">' + cellText + '</option>');
                                }
                            });

                            // Add change event listener to the dropdown
                            select.on('change', function () {
                                var val = $.fn.dataTable.util.escapeRegex(
                                    $(this).val()
                                );

                                // Search and filter the table
                                column.search(val ? '^' + val + '$' : '', true, false).draw();
                            });

                        });

                    this.api()
                        .columns([7])
                        .every(function () {//Category Type
                            $(select).click(function (e) {
                                e.stopPropagation();
                            });
                            var column = this;

                            // Create the dropdown with "All" as the default option
                            var select = $('<br/><select class="form-control" placeholder="filter" style="width:100%;margin-top:5px;background-color:white"><option value="" selected>All</option></select>')
                                .appendTo($(column.header()));
                            $(select).click(function (e) {
                                e.stopPropagation();
                            });
                            // Keep track of unique values
                            var uniqueValues = {};

                            // Populate the dropdown with unique values from column 5
                            column.data().each(function (d, j) {
                                // Get the HTML content of the cell
                                var cellText = column.cell(j, 7).node().innerHTML;
                                // Check if the value is unique before adding it to the dropdown
                                if (!uniqueValues[cellText]) {
                                    uniqueValues[cellText] = true;
                                    select.append('<option value="' + cellText + '">' + cellText + '</option>');
                                }
                            });

                            // Add change event listener to the dropdown
                            select.on('change', function () {
                                var val = $.fn.dataTable.util.escapeRegex(
                                    $(this).val()
                                );

                                // Search and filter the table
                                column.search(val ? '^' + val + '$' : '', true, false).draw();
                            });

                        });

                    // this.api()
                    //     .columns([11])
                    //     .every(function () {//state
                    //         $(select).click(function (e) {
                    //             e.stopPropagation();
                    //         });
                    //         var column = this;

                    //         // Create the dropdown with "All" as the default option
                    //         var select = $('<br/><select class="form-control" placeholder="filter" style="width:100%;margin-top:5px;background-color:white"><option value="" selected>All</option></select>')
                    //             .appendTo($(column.header()));
                    //         $(select).click(function (e) {
                    //             e.stopPropagation();
                    //         });
                    //         // Keep track of unique values
                    //         var uniqueValues = {};

                    //         // Populate the dropdown with unique values from column 5
                    //         column.data().each(function (d, j) {
                    //             // Get the HTML content of the cell
                    //             var cellText = column.cell(j, 11).node().innerHTML;
                    //             // Check if the value is unique before adding it to the dropdown
                    //             if (!uniqueValues[cellText]) {
                    //                 uniqueValues[cellText] = true;
                    //                 select.append('<option value="' + cellText + '">' + cellText + '</option>');
                    //             }
                    //         });

                    //         // Add change event listener to the dropdown
                    //         select.on('change', function () {
                    //             var val = $.fn.dataTable.util.escapeRegex(
                    //                 $(this).val()
                    //             );

                    //             // Search and filter the table
                    //             column.search(val ? '^' + val + '$' : '', true, false).draw();
                    //         });

                    //     });
                    this.api()
                        .columns([11])
                        .every(function () {//Exclusive
                            $(select).click(function (e) {
                                e.stopPropagation();
                            });
                            var column = this;

                            // Create the dropdown with "All" as the default option
                            var select = $('<br/><select class="form-control" placeholder="filter" style="width:100%;margin-top:5px;background-color:white"><option value="" selected>All</option></select>')
                                .appendTo($(column.header()));
                            $(select).click(function (e) {
                                e.stopPropagation();
                            });
                            // Keep track of unique values
                            var uniqueValues = {};

                            // Populate the dropdown with unique values from column 5
                            column.data().each(function (d, j) {
                                // Get the HTML content of the cell
                                var cellText = column.cell(j, 11).node().innerHTML;
                                // Check if the value is unique before adding it to the dropdown
                                if (!uniqueValues[cellText]) {
                                    uniqueValues[cellText] = true;
                                    select.append('<option value="' + cellText + '">' + cellText + '</option>');
                                }
                            });

                            // Add change event listener to the dropdown
                            select.on('change', function () {
                                var val = $.fn.dataTable.util.escapeRegex(
                                    $(this).val()
                                );

                                // Search and filter the table
                                column.search(val ? '^' + val + '$' : '', true, false).draw();
                            });

                        });
                    this.api()
                        .columns([12])//Status
                        .every(function () {
                            var column = this;
                            var select = $('<br/><select class="form-control" placeholder="search" style="width:100%;margin-top:5px;background-color:white""><option value="">All</option></select>')
                                .appendTo($(column.header()))
                                .on('change', function () {
                                    var val = $.fn.dataTable.util.escapeRegex($(this).val());
                                    column.search(val ? '^' + val + '$' : '', true, false).draw();
                                });
                            $(select).click(function (e) {
                                e.stopPropagation();
                            });
                            $(select).click(function (e) {
                                e.stopPropagation();
                            });
                            column
                                .data()
                                .unique()
                                .sort()
                                .each(function (d, j) {
                                    let name = [{ "name": "Active", "value": 0 }, { "name": "In-active", "value": 1 }]?.find(stat => stat.value == d)?.name;
                                    select.append('<option value="' + name + '">' + name + '</option>');
                                });
                        });
                },
            });
        table.on('click', 'td', function () {
            // Get the column index of the clicked cell
            const columnIndex = table.cell(this).index()?.column;
            // Check if the clicked cell belongs to the desired column
            if (columnIndex === 4) {
                // Get the data for the clicked cell
                const rowData = table.row($(this).closest('tr')).data();
                // Handle the event for the clicked cell
                if (rowData) {
                    onView(rowData)
                }
            }
        });
        // Extra step to do extra clean-up.
        return function () {
            $(`#${tableName}`).off('length.dt');
            table.destroy()
        }
    }, [jobList])

    const onEdit = (item) => {
        navigate('/admin/add-job', { state: { item: item } });
    }

    const onCopy = (item) => {
        item.id = null
        navigate('/admin/add-job', { state: { item: item } });
    }

    const onView = (item) => {
        AdminStore.getJobDetails({ 'job_id': item?.id })?.then((data) => {
            item.apllication_list = data;
            setSelectedJob(item);
        });
        setIsOpen(true)
    }

    const onDismiss = () => {
        setIsOpen(false);
        setSelectedJob([])
    }

    return (
        <React.Fragment>
            {/* <body> */}
            <Helmet>
                <title>Staffers LLC | Jobs</title>
            </Helmet>
            <div className="container-scroller">
                <HeaderPage />
                <div className="container-fluid page-body-wrapper">
                    <DrawerMenuPage />
                    <div className="main-panel" style={{ right: 0, position: "absolute" }}>
                        <div className="content-wrapper p-3 bg-white ">
                            <h3 className="page-title">
                                <span className="page-title-icon linear text-white me-2">
                                    <i className="fas fa-user-friends"></i>
                                </span> Jobs
                            </h3>
                            <div className='col-12 d-flex justify-content-lg-end pt-lg-0 pt-2'>
                                <a onClick={() => AdminStore.loading = true} href="/admin/add-job">
                                    <h1 className="page-title signup-link fs-4" >
                                        <span className="page-title-icon linear text-white me-2">
                                            <i className="fas fa-plus"></i>
                                        </span>Add New Job
                                    </h1>
                                </a>
                            </div>
                        </div>
                        <div className="row bg-white table-responsive" style={{ paddingTop: 20 }}>
                            <div className="table-wrapper">
                                <table className="table dt-responsive table-hover nowrap" style={{ width: "100%" }} id={tableName} ref={tableRef}>
                                </table>
                            </div>
                        </div>

                    </div>

                    {/* <!-- main-panel ends --> */}
                </div>
                {/* <!-- page-body-wrapper ends --> */}

            </div>
            {isOpen && <BottomSheet open={isOpen} onDismiss={onDismiss} scrollLocking={true}
                header={
                    <>
                        <div className="text-end" style={{ cursor: "pointer" }} onClick={onDismiss}>
                            <i className="fa fa-times" aria-hidden="true"></i>
                        </div>
                        <h4 className="flex items-center text-xl justify-center font-bold text-primary-800">
                            JOB DETAILS #{selectedJob?.id}  {selectedJob?.is_exclusive === 1 ? <img src={Images.exclusive} width={50} /> : ""}
                        </h4>
                    </>
                }
            >
                <div style={{ height: '70vh', overflowY: 'scroll' }}>
                    <div className="col-xl-12 col-md-12">
                        <div className="user-card-full">
                            <div className="row m-l-0 m-r-0">
                                <div className="col-sm-12">
                                    <div className="card-block">
                                        {/* <h6 className="m-b-20 p-b-5 b-b-default f-w-600">Job </h6> */}
                                        <div className="row">
                                            {selectedJob?.industry && <div className="col-sm-2">
                                                <p className="m-b-10 f-w-600">Industry</p>
                                                <h6 className="text-muted f-w-400">{selectedJob?.industry}</h6>
                                            </div>}
                                            {selectedJob?.shift_type && <div className="col-sm-2">
                                                <p className="m-b-10 f-w-600">Shift Type</p>
                                                <h6 className="text-muted f-w-400">{JSON?.parse(selectedJob?.shift_type)?.map(x => x).join(', ') || ""}</h6>
                                            </div>}
                                            {selectedJob?.contract_type && <div className="col-sm-2">
                                                <p className="m-b-10 f-w-600">Contact Type</p>
                                                <h6 className="text-muted f-w-400">{selectedJob?.contract_type}</h6>
                                            </div>}
                                            {selectedJob && <div className="col-sm-2">
                                                <p className="m-b-10 f-w-600">Degree-Specialty</p>
                                                <h6 className="text-muted f-w-400">{
                                                    selectedJob?.speciality ? JSON.parse(selectedJob?.speciality)?.Name : selectedJob?.degree ? JSON.parse(selectedJob?.degree)?.Name : "-"
                                                }</h6>
                                            </div>}
                                            {selectedJob?.pay_details_max && <div className="col-sm-2">
                                                <p className="m-b-10 f-w-600">Pay Details</p>
                                                <h6 className="text-muted f-w-400">${selectedJob?.pay_details_min} - ${selectedJob?.pay_details_max} </h6>
                                            </div>}
                                            {selectedJob?.city && <div className="col-sm-2">
                                                <p className="m-b-10 f-w-600">City-State</p>
                                                <h6 className="text-muted f-w-400">{selectedJob?.city}, {selectedJob?.state} </h6>
                                            </div>}
                                            {selectedJob?.end_date && <div className="col-sm-2">
                                                <p className="m-b-10 f-w-600">Start Date To End Date</p>
                                                {console.log(selectedJob?.start_date)}
                                                <h6 className="text-muted f-w-400">{moment(selectedJob?.start_date, 'YYYY-MM-DD').format('MMMM DD, YYYY')} To {moment(selectedJob?.end_date, 'YYYY-MM-DD').format('MMMM DD, YYYY')} </h6>
                                            </div>}
                                            {selectedJob?.gauranteed_hours && <div className="col-sm-2">
                                                <p className="m-b-10 f-w-600">Gauranteed Hours</p>
                                                <h6 className="text-muted f-w-400"> {selectedJob?.gauranteed_hours} </h6>
                                            </div>}
                                            {selectedJob?.length_weeks && <div className="col-sm-2">
                                                <p className="m-b-10 f-w-600">Length Of Contact</p>
                                                <h6 className="text-muted f-w-400"> {selectedJob?.length_weeks} </h6>
                                            </div>}
                                            {selectedJob?.opening && <div className="col-sm-2">
                                                <p className="m-b-10 f-w-600">No. Of Opening</p>
                                                <h6 className="text-muted f-w-400"> {selectedJob?.opening} </h6>
                                            </div>}

                                            {selectedJob && selectedJob?.qualifications && <div class="col-sm-6">
                                                <p class="m-b-10 f-w-600">Qualifications</p>
                                                {/* <h6 class="text-muted f-w-400">{signleData?.content}</h6> */}
                                                <div dangerouslySetInnerHTML={{ __html: selectedJob?.qualifications }} />
                                            </div>}
                                            {selectedJob && selectedJob?.benefits && <div class="col-sm-6">
                                                <p class="m-b-10 f-w-600">Benefits</p>
                                                {/* <h6 class="text-muted f-w-400">{signleData?.content}</h6> */}
                                                <div dangerouslySetInnerHTML={{ __html: selectedJob?.benefits }} />
                                            </div>}
                                            {selectedJob && selectedJob?.description && <div class="col-sm-6">
                                                <p class="m-b-10 f-w-600">Description</p>
                                                {/* <h6 class="text-muted f-w-400">{signleData?.content}</h6> */}
                                                <div dangerouslySetInnerHTML={{ __html: selectedJob?.description }} />
                                            </div>}
                                            <h4 className="m-b-20 p-b-5 b-b-default f-w-600">Applications </h4>
                                            {
                                                selectedJob?.apllication_list?.length > 0 ?
                                                    <CTable>
                                                        <CTableHead>
                                                            <CTableRow>
                                                                <CTableHeaderCell scope="col">#</CTableHeaderCell>
                                                                <CTableHeaderCell scope="col">Name</CTableHeaderCell>
                                                                <CTableHeaderCell scope="col">Contact</CTableHeaderCell>
                                                                <CTableHeaderCell scope="col">Email Address</CTableHeaderCell>
                                                                {/* <CTableHeaderCell scope="col">Address</CTableHeaderCell> */}
                                                                <CTableHeaderCell scope="col">Applied Date</CTableHeaderCell>
                                                                <CTableHeaderCell scope="col">Application Status</CTableHeaderCell>
                                                            </CTableRow>
                                                        </CTableHead>
                                                        <CTableBody>
                                                            {selectedJob?.apllication_list?.map((item, index) => {
                                                                return <CTableRow key={index + "list"}>
                                                                    <CTableHeaderCell scope="row">{index + 1}</CTableHeaderCell>
                                                                    <CTableDataCell><strong><a onClick={() => showCaregiverProfile(item)} className="text-info text-decoration-underline pointer">{item?.staff?.first_name} {item?.staff?.last_name}</a></strong></CTableDataCell>
                                                                    <CTableDataCell>
                                                                        <a href={`tel:${item?.staff?.country_code}${item?.staff?.contact}`}>
                                                                            <i className="fas fa-phone-alt"></i> {item?.staff?.country_code} {item?.staff?.contact}
                                                                        </a></CTableDataCell>
                                                                    <CTableDataCell>
                                                                        <a href={`mailto:${item?.staff?.email}`}>
                                                                            <i className="far fa-envelope"></i> {item?.staff?.email}
                                                                        </a></CTableDataCell>
                                                                    {/* <CTableHeaderCell >{item?.staff?.address + ", " + item?.staff?.city + ", " + item?.staff?.state}</CTableHeaderCell> */}
                                                                    <CTableHeaderCell >{moment(item?.created_at).format('MM/DD/YYYY hh:mm A')}</CTableHeaderCell>
                                                                    <CTableHeaderCell >{item?.status === 0 ? "Inactive" : "Active"}</CTableHeaderCell>

                                                                </CTableRow>
                                                            })}

                                                        </CTableBody>
                                                    </CTable>
                                                    : <p>No Application Found!</p>
                                            }

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </BottomSheet >}
            <Spinner isLoading={AdminStore.loading} />

        </React.Fragment >
    );
})

export default Jobs;
