/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import { observer } from "mobx-react"
import HeaderPage from "../layout/HeaderPage";
import DrawerMenuPage from "../layout/DrawerMenuPage";
import './UpdatePages.css'
import Spinner from "@components/Spinner";
import AdminStore from "@store/AdminStore";
import HomeStore from "@store/HomeStore";
import { toJS } from "mobx";
import { Helmet } from "react-helmet";
import { contactQuery, enqStatusList,jobsCompany } from '@utils/constant/constant';
import { CButton, CDropdown, CDropdownItem, CDropdownMenu, CDropdownToggle, CModal, CModalBody, CModalHeader, CModalTitle } from "@coreui/react-pro";
import { Formik, Form } from "formik";
import { InputField } from "@elements/index"
import Select, { components } from 'react-select';
import SelectField from "@elements/select/SelectField";
import { colourStylesState, colourStyles } from '@utils/customStyle/style';
import ReactDOM from "react-dom";
import { EnquiryValidationSchema } from '@utils/schemas/allSchema';
import { BottomSheet } from "react-spring-bottom-sheet";
import moment from "moment";

var citys = require('@assets/city.json');
var states = require('@assets/states.json');
const Enquiry = observer(() => {

    const tableRef = useRef()
    const tableName = "table1"
    const [paginationLength, setPaginationLength] = useState(25);
    const [enquiriesList, setEnquiriesList] = useState([]);
    const [selectedEnquiry, setSelectedEnquiry] = useState();
    const [isOpen, setIsOpen] = useState(false);
    const [viewOpen, setViewOpen] = useState(false);
    const [degrees, setDegrees] = useState([]);
    const [selectedEnq, setSelectedEnq] = useState([]);

    useEffect(() => {
        AdminStore.getEnquiry();
    }, []);
    useEffect(() => {
        const degreesData = HomeStore.data.dashboardData?.find(
            (x) => x.key_name === "DEGREES"
        );
        if (degreesData) setDegrees(JSON.parse(toJS(degreesData.value)));
    }, [HomeStore.data.dashboardData]);

    useEffect(() => {
        setEnquiriesList(toJS(AdminStore?.data?.enquiry))
    }, [AdminStore?.data?.enquiry]);


    useEffect(() => {
        $.fn.dataTable.ext.errMode = 'none';
        $($.fn.dataTable.tables(true)).DataTable()
            .columns.adjust();
        const table = $(`#${tableName}`).DataTable(
            {
                data: enquiriesList,
                order: [], // Disable initial sorting
                dom: 'Blfrtip',
                buttons: [
                    'copy', 'csv', 'excel', 'print',
                ],
                pageLength: paginationLength,
                lengthMenu: [25, 50, 100, 500, 1000],
                columns: [
                    {
                        title: "Ref.", data: "id", "render": function (data, type, row, meta) {
                            return data || "-";
                        }
                    },
                    {
                        title: "Inq. Req. Time", data: "created_at", "render": function (data, type, row, meta) {
                            return moment(data).format('MM/DD/yy hh:mm A') || "-";
                        }
                    },
                    {
                        title: "Inquiry Type", data: "enquiry_type", "render": function (data, type, row, meta) {
                            return contactQuery?.find(x => x.id == data)?.enquiry || "-";
                        }
                    },
                    {
                        title: "Name", data: "name", "render": function (data, type, row, meta) {
                            return '<a class="link-info pointer">' + data + '</a>';
                        }
                    },
                    {
                        title: "Email-Contact", render: function (data, type, row, meta) {
                            const emailIcon = '<i class="fas fa-envelope"></i>'; // Font Awesome email icon
                            const phoneIcon = '<i class="fas fa-phone-alt"></i>'; // Font Awesome phone icon

                            const email = row?.email ? `${emailIcon} ${row.email}` : "-";
                            const contact = row?.contact ? `${phoneIcon} ${row.contact}` : "-";

                            return `${email}<br/><br/>${contact}`;
                        }
                    },
                    {
                        title: "Job Title", data: "designation", "render": function (data, type, row, meta) {
                            const professionNames = data && JSON?.parse(data)?.map(x => x?.Name);
                            return data ? professionNames?.join("<br/>") : "-";
                        }
                    },
                    {
                        title: "Company Name", data: "company_name"
                    },
                    {
                        title: "City-State", render: function (data, type, row, meta) {
                            const emailIcon = '<i class="fas fa-map-marker-alt"></i>'; // Font Awesome email icon                            
                            const city = row?.city ? `${emailIcon} ${row.city}` : "-";
                            const state = row?.state ? `${row.state}` : "-";

                            return `${city},${state}`;
                        }
                    },
                    {
                        title: "Message", data: "message"
                    },
                    {
                        title: "Status", data: 'status', "render": function (data, type, row, meta) {
                            return data == '1' ? '<span class="text-muted">' + "Closed" + '</span>' : data == '0' ? '<span class="text-warning">' + "Pending" + '</span>' : 'No Update'
                        }
                    },
                    {
                        title: "Manage"
                    },
                ],
                columnDefs: [
                    {
                        targets: 10,
                        responsivePriority: 1,
                        createdCell: (td, cellData, rowData, row, col) =>
                            ReactDOM.render(
                                <div>
                                    <CDropdown variant="btn-group" direction='dropstart' alignment="end">
                                        <CDropdownToggle size="sm" color="transparent" caret={false} href="#" className="p-0"><i className="fa fa-ellipsis-v text-primary"></i></CDropdownToggle>
                                        <CDropdownMenu>
                                            <CDropdownItem className="more-menu">
                                                <CButton onClick={() => {
                                                    onEdit(rowData)
                                                }} size="sm" className="w-100" variant='outline' color="primary" >
                                                    Edit <i className="fas fa-edit"></i>
                                                </CButton>
                                            </CDropdownItem>
                                        </CDropdownMenu>
                                    </CDropdown>
                                </div>
                                , td),
                    },
                    {
                        targets: 9,
                        responsivePriority: 2,
                    },
                    {
                        targets: 7,
                        responsivePriority: 3,
                    },
                    {
                        targets: 4,
                        responsivePriority: 5,
                    },
                ],
                destroy: true,  // I think some clean up is happening here
                searching: true,
                "language": {
                    search: '',
                    searchPlaceholder: "Search",
                    emptyTable: "No Inquiry Found",
                },
                initComplete: function () {
                    this.api()
                        .columns([0,3, 4, 5, 6])
                        .every(function () {
                            var column = this;
                            var select = $('<br/><input class="form-control " style="width:100%;margin-top:5px" type="text" placeholder="Filter per page" />')
                                .appendTo($(column.header()))

                            $(select).click(function (e) {
                                e.stopPropagation();
                            });
                            $('input', this.header()).on('keyup change clear', function () {
                                if (column.search() !== this.value) {
                                    column.search(this.value).draw();
                                }
                            });
                        });
                        this.api()
                        .columns([1])
                        .every(function () {
                            var column = this;
                            var select = $('<br/><div class="input-daterange input-group" style="width:250px;margin-top:5px"></div>')
                                .appendTo($(column.header()));

                            var startDateInput = $('<input type="text" class="form-control" placeholder="Start Date" style="border-top-left-radius:8px;border-bottom-left-radius:8px;"/>');
                            var endDateInput = $('<input type="text" class="form-control" placeholder="End Date"  style="border-top-right-radius:8px;border-bottom-right-radius:8px;"/>');

                            startDateInput.appendTo(select);
                            endDateInput.appendTo(select);

                            $('.input-daterange').datepicker({
                                format: 'mm-dd-yyyy', // Specify your desired date format here
                                // autoclose: true,
                                todayHighlight: true, // Highlight today's date
                                clearBtn: true, // Show the "Clear" button
                                orientation: 'bottom',
                                daysOfWeekHighlighted: '0,6', // Highlight weekends (0: Sunday, 6: Saturday)
                                templates: {
                                    leftArrow: '<i class="fas fa-chevron-left"></i>', // Customize the left arrow icon
                                    rightArrow: '<i class="fas fa-chevron-right"></i>', // Customize the right arrow icon
                                },
                            });

                            $(select).click(function (e) {
                                e.stopPropagation();
                            });
                            $('input', this.header()).on('keyup change clear', function () {
                                if (!this.value) {
                                    $(`#${tableName}`).DataTable().column([1]).search('').draw();
                                }
                                if (column.search() !== this.value) {
                                    column.search(moment(this.value).format('MM/DD/YYYY')).draw();
                                }
                            });
                        });
                    this.api()
                        .columns([2])
                        .every(function () {//city
                            $(select).click(function (e) {
                                e.stopPropagation();
                            });
                            var column = this;

                            // Create the dropdown with "All" as the default option
                            var select = $('<br/><select class="form-control" placeholder="filter" style="width:100%;margin-top:5px;background-color:white"><option value="" selected>All</option></select>')
                                .appendTo($(column.header()));
                            $(select).click(function (e) {
                                e.stopPropagation();
                            });
                            // Keep track of unique values
                            var uniqueValues = {};

                            // Populate the dropdown with unique values from column 5
                            column.data().each(function (d, j) {
                                // Get the HTML content of the cell
                                var cellText = column.cell(j, 1).node().innerHTML;
                                // Check if the value is unique before adding it to the dropdown
                                if (!uniqueValues[cellText]) {
                                    uniqueValues[cellText] = true;
                                    select.append('<option value="' + cellText + '">' + cellText + '</option>');
                                }
                            });

                            // Add change event listener to the dropdown
                            select.on('change', function () {
                                var val = $.fn.dataTable.util.escapeRegex(
                                    $(this).val()
                                );

                                // Search and filter the table
                                column.search(val ? '^' + val + '$' : '', true, false).draw();
                            });

                        });
                    this.api()
                        .columns([8])
                        .every(function () {//city
                            $(select).click(function (e) {
                                e.stopPropagation();
                            });
                            var column = this;

                            // Create the dropdown with "All" as the default option
                            var select = $('<br/><select class="form-control" placeholder="filter" style="width:100%;margin-top:5px;background-color:white"><option value="" selected>All</option></select>')
                                .appendTo($(column.header()));
                            $(select).click(function (e) {
                                e.stopPropagation();
                            });
                            // Keep track of unique values
                            var uniqueValues = {};

                            // Populate the dropdown with unique values from column 5
                            column.data().each(function (d, j) {
                                // Get the HTML content of the cell
                                var cellText = column.cell(j, 7).node().innerHTML;
                                // Check if the value is unique before adding it to the dropdown
                                if (!uniqueValues[cellText]) {
                                    uniqueValues[cellText] = true;
                                    select.append('<option value="' + cellText + '">' + cellText + '</option>');
                                }
                            });

                            // Add change event listener to the dropdown
                            select.on('change', function () {
                                var val = $.fn.dataTable.util.escapeRegex(
                                    $(this).val()
                                );

                                // Search and filter the table
                                column.search(val ? '^' + val + '$' : '', true, false).draw();
                            });

                        });
                    this.api()
                        .columns([9])//Status
                        .every(function () {
                            var column = this;
                            var select = $('<br/><select class="form-control" placeholder="search" style="width:100%;margin-top:5px;background-color:white""><option value="">All</option></select>')
                                .appendTo($(column.header()))
                                .on('change', function () {
                                    var val = $.fn.dataTable.util.escapeRegex($(this).val());
                                    column.search(val ? '^' + val + '$' : '', true, false).draw();
                                });
                            $(select).click(function (e) {
                                e.stopPropagation();
                            });
                            $(select).click(function (e) {
                                e.stopPropagation();
                            });
                            column
                                .data()
                                .unique()
                                .sort()
                                .each(function (d, j) {
                                    let name = [{ "name": "Pending", "value": 0 }, { "name": "Closed", "value": 1 }]?.find(stat => stat.value == d)?.name;
                                    select.append('<option value="' + name + '">' + name + '</option>');
                                });
                        });
                },

            });
        table.on('click', 'td', function () {
            // Get the column index of the clicked cell
            const columnIndex = table.cell(this).index()?.column;
            // Check if the clicked cell belongs to the desired column
            if (columnIndex === 2) {
                // Get the data for the clicked cell
                const rowData = table.row($(this).closest('tr')).data();
                // Handle the event for the clicked cell
                if (rowData) {
                    onView(rowData)
                }
            }
        });
        // Extra step to do extra clean-up.
        return function () {
            $(`#${tableName}`).off('length.dt');
            table.destroy()
        }
    }, [enquiriesList])

    const onRefresh = () => {
        setIsOpen(false)
        setSelectedEnquiry()
        AdminStore.getEnquiry();
    }
    const addEnquiry = (values) => {
        if (selectedEnquiry?.id) {
            AdminStore.updateEnquiry(values, onRefresh)
        } else {
            values.designation = JSON.stringify(values.designation)
            AdminStore.addEnquiry(values, onRefresh)
        }
    }
    const openModal = () => {
        setSelectedEnquiry()
        setIsOpen(true)
    }
    const onDismiss = () => {
        setSelectedEnquiry()
        setIsOpen(false)
    }
    const onEdit = (data) => {
        setSelectedEnquiry(data)
        setIsOpen(true)
    }
    const onView = (item) => {
        setSelectedEnq(item);
        setViewOpen(true)
    }
    const onDismissView = () => {
        setSelectedEnq()
        setViewOpen(false)
    }
    return (
        <React.Fragment>
            {/* <body> */}
            <Helmet>
                <title>Staffers LLC | Inquiry</title>
            </Helmet>
            <div className="container-scroller">
                <HeaderPage />
                <div className="container-fluid page-body-wrapper">
                    <DrawerMenuPage />
                    <div className="main-panel" style={{ right: 0, position: "absolute" }}>
                        <div className="content-wrapper p-3 bg-white ">
                            <h3 className="page-title">
                                <span className="page-title-icon linear text-white me-2">
                                    <i className="fas fa-user-friends"></i>
                                </span> Inquiry
                            </h3>
                            <div className='col-12 d-flex justify-content-lg-end pt-lg-0 pt-2'>
                                <a onClick={() => openModal(true)} href="#">
                                    <h1 className="page-title signup-link fs-4" >
                                        <span className="page-title-icon linear text-white me-2">
                                            <i className="fas fa-plus"></i>
                                        </span>Add New Inquiry
                                    </h1>
                                </a>
                            </div>
                        </div>
                        <div className="row bg-white table-responsive" style={{ paddingTop: 20 }}>
                            <div className="table-wrapper">
                                <table className="table dt-responsive table-hover nowrap" style={{ width: "100%" }} id={tableName} ref={tableRef}>
                                </table>
                            </div>
                        </div>

                    </div>

                    {/* <!-- main-panel ends --> */}
                </div>
                {/* <!-- page-body-wrapper ends --> */}
                <CModal className="custom-modal-size" backdrop="static" visible={isOpen} onClose={onDismiss} >
                    <CModalHeader onClose={onDismiss} >
                        <CModalTitle>{selectedEnquiry ? "Edit" : "Add"} Inquiry</CModalTitle>
                    </CModalHeader>
                    <CModalBody>
                        <Formik
                            initialValues={{
                                'id': selectedEnquiry?.id || '',
                                'enquiry_type': selectedEnquiry?.enquiry_type || '1',
                                'name': selectedEnquiry?.name || '',
                                'email': selectedEnquiry?.email || '',
                                'contact': selectedEnquiry?.contact || '',
                                'designation': selectedEnquiry?.designation ? JSON.parse(selectedEnquiry?.designation) : [],
                                'company_name': selectedEnquiry?.company_name || '',
                                'city': selectedEnquiry?.city || '',
                                'state': selectedEnquiry?.state || '',
                                'message': selectedEnquiry?.message || '',
                                'status': selectedEnquiry?.status || '0',
                                'degree': selectedEnquiry?.degree || null,
                                'positions': selectedEnquiry?.positions || null,
                                'available_date': selectedEnquiry?.available_date || null,
                                'referral_name': selectedEnquiry?.referral_name || '',
                                'location': selectedEnquiry?.location || '',
                                'job_type': selectedEnquiry?.job_type || '',
                                'fillable_positions': selectedEnquiry?.fillable_positions || '',
                                'heard_us': selectedEnquiry?.heard_us || '',
                            }}
                            validationSchema={EnquiryValidationSchema}
                            validateOnChange={false}
                            onSubmit={(values, { resetForm }) => {
                                addEnquiry(values);
                            }}
                        >
                            {({ values, errors, handleBlur, handleChange, setFieldValue }) => (
                                <Form>
                                    <div className="row g-2">
                                        <div className="col-sm-6 mb-2">
                                            <SelectField
                                                name="enquiry_type"
                                                label={"Inquiry Type"}
                                                value={values.enquiry_type}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                data={contactQuery}
                                                optionLabel={"enquiry"}
                                                optionValue={"id"}
                                            />
                                            {errors.enquiry_type ? (
                                                <div className="error-message">{errors.enquiry_type}</div>
                                            ) : null}
                                        </div>
                                        <div className="col-sm-6 mb-2">
                                            <InputField
                                                className={"did-floating-input"}
                                                classNameLabel={"did-floating-label did-floating-labe-astrisk"}
                                                type="text"
                                                name="name"
                                                label={"Full Name"}
                                                value={values.name}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                required={true}
                                            />
                                            {errors.name ? (
                                                <div className="error-message">{errors.name}</div>
                                            ) : null}
                                        </div>
                                        <div className="col-sm-6 mb-2">
                                            <InputField
                                                className={"did-floating-input"}
                                                classNameLabel={"did-floating-label did-floating-labe-astrisk"}
                                                type="email"
                                                name="email"
                                                label={"Email"}
                                                value={values.email}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                required={true}
                                            />
                                            {errors.email ? (
                                                <div className="error-message">{errors.email}</div>
                                            ) : null}
                                        </div>
                                        <div className="col-sm-6 mb-2">
                                            <InputField
                                                className={"did-floating-input"}
                                                classNameLabel={"did-floating-label did-floating-labe-astrisk"}
                                                type="text"
                                                name="contact"
                                                label={"Contact Number"}
                                                value={values?.contact?.replace(/[^\d]/g, '').replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3')}
                                                maxLength={12}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                required={true}
                                            />
                                            {errors.contact ? (
                                                <div className="error-message">{errors.contact}</div>
                                            ) : null}
                                        </div>
                                        <div className="col-sm-6 mb-2">
                                            <Select
                                                onChange={(e) => { setFieldValue("state", e.abbreviation), setFieldValue("city", '') }}
                                                placeholder={''}
                                                label={'State'}
                                                styles={colourStylesState}
                                                value={states.filter(({ abbreviation }) => values?.state?.includes(abbreviation)) || ''}
                                                options={states}
                                                getOptionLabel={(option) => option.name}
                                                getOptionValue={(option) => option.abbreviation}
                                                components={{ Control, DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                                            />
                                            {errors.state ? (
                                                <div className="error-message">{errors.state}</div>
                                            ) : null}
                                        </div>
                                        <div className="col-sm-6 mb-2">
                                            <Select
                                                onChange={(e) => { setFieldValue("city", e.label); setFieldValue("city_data", [JSON.stringify(e)]) }}
                                                placeholder={''}
                                                label={'City'}
                                                isDisabled={!values?.state}
                                                styles={colourStylesState}
                                                getOptionLabel={(option) => option.label}
                                                getOptionValue={(option) => option.label}
                                                value={citys.filter(({ label }) => values?.city === label) || ''}
                                                options={citys?.filter(ct => ct.state === states?.find(x => x.abbreviation == values?.state)?.name)}
                                                components={{ Control, DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                                            />
                                            {errors.city ? (
                                                <div className="error-message">{errors.city}</div>
                                            ) : null}
                                        </div>
                                        {values?.enquiry_type == '2' && <>
                                            <div className="col-sm-6 mb-2">
                                                <InputField
                                                    className={"did-floating-input"}
                                                    type="text"
                                                    name="company_name"
                                                    label={"Company Name"}
                                                    value={values.company_name}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                />
                                            </div>


                                            <div className="col-md-6 mb-3">
                                                <InputField
                                                    className={"did-floating-input"}
                                                    type="number"
                                                    name={"positions"}
                                                    value={values?.positions || ""}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    label={"Total nurses required at your facility?"}
                                                    required={true}
                                                    min={1}
                                                />
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <InputField
                                                    className={"did-floating-input"}
                                                    classNameLabel={"did-floating-label"}
                                                    type="date"
                                                    name="available_date"
                                                    label={"When do you need them by?"}
                                                    value={values.available_date}
                                                    min={new Date().toISOString().split('T')[0]}
                                                    onChange={e => {
                                                        setFieldValue('available_date', e.target.value);
                                                    }}
                                                    onBlur={handleBlur}
                                                    required={false}
                                                />
                                                {errors.available_date ? (
                                                    <div className="error-message">
                                                        {errors.available_date}
                                                    </div>
                                                ) : null}
                                            </div>

                                        </>}
                                        {(values?.enquiry_type == '2' || values?.enquiry_type == '3' || values?.enquiry_type == '4' || values?.enquiry_type == '5') &&
                                            <div className="col-sm-6 mb-2">
                                                <Select
                                                    getOptionLabel={(option) =>
                                                        `${option.Name}`
                                                    }
                                                    getOptionValue={(option) =>
                                                        option.Id
                                                    }
                                                    isMulti
                                                    closeMenuOnSelect={false}
                                                    options={degrees}
                                                    styles={colourStyles}
                                                    placeholder=""
                                                    value={values.designation}
                                                    label={values?.enquiry_type === "2"
                                                        ? "What positions do you need filled"
                                                        : "What positions are you interested in?"}
                                                    onChange={(selectedOption) => {
                                                        handleChange({
                                                            target: {
                                                                name: "designation",
                                                                value: selectedOption,
                                                            },
                                                        });
                                                    }}
                                                    components={{
                                                        Control,
                                                        MultiValue,
                                                        DropdownIndicator: () => null,
                                                        IndicatorSeparator: () => null,
                                                    }}
                                                />
                                                {errors.designation ? (
                                                    <div className="error-message">{errors.designation}</div>
                                                ) : null}

                                            </div>}
                                        {
                                            values?.enquiry_type == '5' && (
                                                <div className="col-md-6 mb-3">
                                                    <InputField
                                                        className={"did-floating-input"}
                                                        classNameLabel={"did-floating-label did-floating-labe-astrisk"}
                                                        type="text"
                                                        name="referral_name"
                                                        label={"Referral Name"}
                                                        value={values?.referral_name}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        required
                                                    />
                                                    {errors.referral_name ? (
                                                        <div className="error-message">
                                                            {errors.referral_name}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            )
                                        }
                                        {(values?.enquiry_type == '2' || values?.enquiry_type == '3') && <div className="col-sm-6 mb-2">
                                            <InputField
                                                className={"did-floating-input"}
                                                classNameLabel={"did-floating-label"}
                                                type="text"
                                                name="location"
                                                label={"Location"}
                                                value={values?.location}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                        </div>}
                                        {values?.enquiry_type == '3' && <div className="col-sm-6 mb-2">
                                            <SelectField
                                                name="job_type"
                                                label={"Are you interested in a Travel or Local job?"}
                                                value={values.job_type}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                data={[{ "label": "Travel", "value": "Travel" }, { "label": "Local", "value": "Local" }]}
                                                optionLabel={"label"}
                                                optionValue={"value"}
                                            />
                                        </div>}
                                        {<div className="col-sm-6 mb-2">
                                            <SelectField
                                                name="heard_us"
                                                label={"How Did You Hear About Us?"}
                                                value={values.heard_us}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                data={jobsCompany}
                                                optionLabel={"label"}
                                                optionValue={"value"}
                                            />
                                        </div>}
                                        <div className="col-sm-6 mb-2">
                                            <SelectField
                                                name="status"
                                                label={"Status"}
                                                value={values.status}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                data={enqStatusList}
                                                optionLabel={"label"}
                                                optionValue={"value"}
                                            />
                                            {errors.status ? (
                                                <div className="error-message">{errors.status}</div>
                                            ) : null}
                                        </div>
                                        {values?.enquiry_type == '2' &&
                                            <div className="col-sm-12 mb-2">
                                                <label className="pb-2 label">What positions do you need filled?*</label>
                                                <div className="">
                                                    <textarea className="form-control"
                                                        placeholder=""
                                                        name={'fillable_positions'}
                                                        value={values.message}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        style={{ height: 100 }}>
                                                    </textarea>
                                                </div>
                                            </div>
                                        }
                                        <div className="col-sm-12 mb-2">
                                            <label className="pb-2 label">Message</label>
                                            <div className="">
                                                <textarea className="form-control"
                                                    placeholder=""
                                                    name={'message'}
                                                    value={values.message}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    style={{ height: 100 }}>
                                                </textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="pt-4 d-flex justify-content-center pb-3">
                                        <button className="btn btn-primary w-25 p-2" type="submit">Submit</button>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </CModalBody>
                </CModal >
            </div>

            <Spinner isLoading={AdminStore.loading} />
            {viewOpen && <BottomSheet open={viewOpen} onDismiss={onDismissView} scrollLocking={true}
                header={
                    <>
                        <div className="text-end" style={{ cursor: "pointer" }} onClick={onDismissView}>
                            <i className="fa fa-times" aria-hidden="true"></i>
                        </div>
                        <h4 className="flex items-center text-xl justify-center font-bold text-primary-800">
                            INQUIRY DETAILS #{selectedEnq?.id}
                        </h4>
                    </>
                }
            >
                <div style={{ height: '70vh', overflowY: 'scroll' }}>
                    <div className="col-xl-12 col-md-12">
                        <div className="user-card-full">
                            <div className="row m-l-0 m-r-0">
                                <div className="col-sm-12">
                                    <div className="card-block">
                                        {/* <h6 className="m-b-20 p-b-5 b-b-default f-w-600">Job </h6> */}
                                        <div className="row">
                                            {selectedEnq?.enquiry_type && <div className="col-sm-2">
                                                <p className="m-b-10 f-w-600">Inquiry Type</p>
                                                <h6 className="text-success f-w-400">{contactQuery?.find(x => x.id == selectedEnq?.enquiry_type)?.enquiry || "-"}</h6>
                                            </div>}
                                            {selectedEnq?.name && <div className="col-sm-2">
                                                <p className="m-b-10 f-w-600">Name</p>
                                                <h6 className="text-muted f-w-500">{selectedEnq?.name || "-"}</h6>
                                            </div>}
                                            {selectedEnq?.email && <div className="col-sm-2">
                                                <p className="m-b-10 f-w-600">Email Address</p>
                                                <h6 className="text-muted f-w-500">{selectedEnq?.email || "-"}</h6>
                                            </div>}
                                            {selectedEnq?.contact && <div className="col-sm-2">
                                                <p className="m-b-10 f-w-600">Contact</p>
                                                <h6 className="text-muted f-w-500">{selectedEnq?.contact || "-"}</h6>
                                            </div>}
                                            {selectedEnq?.designation && <div className="col-sm-4">
                                                <p className="m-b-10 f-w-600">{selectedEnq?.enquiry_type === "2"
                                                    ? "What positions do you need filled"
                                                    : "What positions are you interested in?"}</p>
                                                <h6 className="text-muted f-w-500">{selectedEnq?.designation && JSON?.parse(selectedEnq?.designation)?.map(x => x?.Name)?.join('\n') || "-"}</h6>
                                            </div>}
                                            {selectedEnq?.company_name && <div className="col-sm-2">
                                                <p className="m-b-10 f-w-600">Company Name</p>
                                                <h6 className="text-muted f-w-500">{selectedEnq?.company_name || "-"}</h6>
                                            </div>}
                                            {selectedEnq?.city && <div className="col-sm-2">
                                                <p className="m-b-10 f-w-600">City-State</p>
                                                <h6 className="text-muted f-w-500">{selectedEnq?.city + " - " + selectedEnq?.state}</h6>
                                            </div>}
                                            {selectedEnq?.referral_name && <div className="col-sm-2">
                                                <p className="m-b-10 f-w-600">Referral Name</p>
                                                <h6 className="text-muted f-w-500">{selectedEnq?.referral_name || "-"}</h6>
                                            </div>}
                                            {selectedEnq?.positions && <div className="col-sm-2">
                                                <p className="m-b-10 f-w-600">Total nurses required at your facility?</p>
                                                <h6 className="text-muted f-w-500">{selectedEnq?.positions || "-"}</h6>
                                            </div>}
                                            {selectedEnq?.available_date && <div className="col-sm-2">
                                                <p className="m-b-10 f-w-600">When do you need them by?</p>
                                                <h6 className="text-muted f-w-500">{selectedEnq?.available_date || "-"}</h6>
                                            </div>}
                                            {selectedEnq?.location && <div className="col-sm-2">
                                                <p className="m-b-10 f-w-600">Location</p>
                                                <h6 className="text-muted f-w-500">{selectedEnq?.location || "-"}</h6>
                                            </div>}
                                            {selectedEnq?.job_type && <div className="col-sm-2">
                                                <p className="m-b-10 f-w-600">Are you interested in a Travel or Local job?</p>
                                                <h6 className="text-muted f-w-500">{selectedEnq?.job_type || "-"}</h6>
                                            </div>}
                                            {selectedEnq?.heard_us && <div className="col-sm-2">
                                                <p className="m-b-10 f-w-600">How Did You Hear About Us?</p>
                                                <h6 className="text-muted f-w-500">{selectedEnq?.heard_us || "-"}</h6>
                                            </div>}
                                            {selectedEnq?.fillable_positions && <div className="col-sm-2">
                                                <p className="m-b-10 f-w-600">Positions</p>
                                                <h6 className="text-muted f-w-500">{selectedEnq?.fillable_positions || "-"}</h6>
                                            </div>}
                                            {selectedEnq?.message && <div className="col-sm-2">
                                                <p className="m-b-10 f-w-600">Message</p>
                                                <h6 className="text-muted f-w-500">{selectedEnq?.message || "-"}</h6>
                                            </div>}
                                            {<div className="col-sm-2">
                                                <p className="m-b-10 f-w-600">Status</p>
                                                <h6 className="text-muted f-w-500">{selectedEnq?.status == '1' ? <span class="text-muted">Closed</span> : selectedEnq?.status == '0' ? <span class="text-warning">Pending</span> : 'No Update' || "-"}</h6>
                                            </div>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </BottomSheet >}
        </React.Fragment >
    );
})

export default Enquiry;
export const Control = (props) => {
    return (
        <>
            <label className={props.hasValue || props.isFocused ? props?.selectProps?.isRequired ? "did-floating-label did-floating-label-top did-floating-labe-astrisk" : "did-floating-label did-floating-label-top" : props?.selectProps?.isRequired ? "did-floating-label did-floating-label did-floating-labe-astrisk" : "did-floating-label did-floating-label"} style={{ zIndex: 100 }}>{props?.selectProps?.label}</label>
            <components.Control {...props} />
        </>
    );
};
const MoreSelectedBadge = ({ items }) => {
    const style = {
        marginLeft: "auto",
        background: "#d4eefa",
        borderRadius: "4px",
        fontFamily: "Open Sans",
        fontSize: "11px",
        padding: "3px",
        order: 99
    };

    const title = items.join(", ");
    const length = items.length;
    const label = `+ ${length} item${length !== 1 ? "s" : ""}`;

    return (
        <div style={style} title={title}>
            {label}
        </div>
    );
};
const MultiValue = ({ index, getValue, ...props }) => {
    const maxToShow = 2;
    const overflow = getValue()
        .slice(maxToShow)
        .map((x) => x.label);

    return index < maxToShow ? (
        <components.MultiValue {...props} />
    ) : index === maxToShow ? (
        <MoreSelectedBadge items={overflow} />
    ) : null;
};